import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import RivalzApi from "../../../config/axios/RivalzApi";
import useConnection from "../../../config/zustand";
import MDTypography from "../../../components/MDTypography";
import rivalzLogo from "../../../assets/images/logos/rivalz-logo.png";
import Button from "@mui/material/Button";
import MDInput from "../../../components/MDInput";
import { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

function Basic() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const sign = async () => {
    try {
      if (!email || !password || !repeatPassword) {
        toast.error("Please fill all fields");
        return;
      }
      if (password !== repeatPassword) {
        toast.error("Passwords do not match");
        return;
      }
      await RivalzApi.register({
        email,
        password,
        referralId: "",
      });
      toast.success("Account created successfully");
      const res = await RivalzApi.login({
        email,
        password,
      });
      const token = res.accessToken;
      await RivalzApi.createEncryptKey(token);
      if (token) {
        localStorage.setItem("jwtToken", token);
        window.location.href = "/dashboard";
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error signing in: ", error);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox mb={3} display={"flex"} gap={1} justifyContent="center" alignItems="center">
            <MDBox component="img" src={rivalzLogo} alt="Brand" width="2rem" />
            <MDBox>
              <MDTypography variant="caption" fontWeight="medium">
                {"Rivalz Console"}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox mb={5} style={{ textAlign: "center" }}>
            <MDTypography variant="h6" fontWeight="medium">
              Welcome to Rivalz Consolo
            </MDTypography>
            <MDTypography variant="caption" fontWeight="regular" color="text">
              Enter your email and password to register
            </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="email"
              label="Email"
              fullWidth
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              label="Password"
              fullWidth
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              label="Repeat Password"
              fullWidth
              onChange={(e) => {
                setRepeatPassword(e.target.value);
              }}
            />
          </MDBox>
          <MDBox display={"flex"} justifyContent="center" alignItems="center">
            <Button
              variant="contained"
              onClick={sign}
              fullWidth={true}
              style={{ backgroundColor: "#4CAF50", color: "#fff" }}
            >
              Sign Up
            </Button>
          </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Don&apos;t have an account?{" "}
              <MDTypography
                component={Link}
                to="/authentication/sign-ip"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Sign in
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
