import Grid from "@mui/material/Grid";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import Card from "@mui/material/Card";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import RivalzApi from "../../../config/axios/RivalzApi";
import Button from "@mui/material/Button";
import useConnection from "../../../config/zustand";
import toast from "react-hot-toast";

function StripeProducts() {
  const { customer, setBackdrop } = useConnection();
  const queryClient = useQueryClient();

  const { data: products, isSuccess: fetchProductSs } = useQuery({
    queryKey: ["products"],
    queryFn: async () => RivalzApi.getProducts(),
  });

  const handleSubscribe = async (product) => {
    try {
      setBackdrop(true);
      if (!customer) {
        throw new Error("Customer not found");
      }
      await RivalzApi.subscription({
        price_id: product.priceId,
        customer_id: customer.customerId,
      });
      await queryClient.refetchQueries({ queryKey: ["subscription"] });
      toast.success(`Subscribed ${product.name} successfully`);
    } catch (e) {
      toast.error(e?.response?.data?.message || e?.message);
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <Grid container mt={{ md: 1, lg: 3 }}>
      {products?.map((e) => (
        <Grid key={e?.["product"]?.id} item xs={12} md={6} lg={4}>
          <Card>
            <MDBox px={2} pt={2} textAlign={"center"}>
              <MDTypography variant="h6" fontWeight="medium">
                {e?.name}
              </MDTypography>
            </MDBox>
            <MDBox px={7} textAlign={"center"}>
              <p
                style={{
                  color: "#8C8C8C",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                {e?.productDescription}
              </p>
            </MDBox>
            <MDBox px={2}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    50 GB First / Month
                  </MDTypography>
                </Grid>
                <Grid item xs={6} md={6} textAlign={"right"}>
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    0.1$ / GB
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    450 GB after
                  </MDTypography>
                </Grid>
                <Grid item xs={6} md={6} textAlign={"right"}>
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    0.08$ / GB
                  </MDTypography>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox p={2} css={{ width: "100%" }}>
              <Button
                variant="contained"
                fullWidth={true}
                style={{ color: "white", backgroundColor: "#181818" }}
                color="warning"
                onClick={() => handleSubscribe(e)}
              >
                Subscribe
              </Button>
            </MDBox>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default StripeProducts;
