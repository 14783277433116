// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import useConnection from "../../../../config/zustand";
import { useQuery } from "@tanstack/react-query";
import RivalzApi from "../../../../config/axios/RivalzApi";
import { Chip } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";

function PlatformSettings() {
  const { customer, userInfo } = useConnection();

  const { data: detailUploaded } = useQuery({
    queryKey: ["detailUploaded"],
    queryFn: async () => RivalzApi.getDetailUploaded(),
  });

  const { data: currentPlan } = useQuery({
    queryKey: ["currentPlan"],
    queryFn: async () => RivalzApi.getCurrentPlan(),
  });

  return (
    <div>
      <Card sx={{ boxShadow: "none" }}>
        <Grid
          sx={{ borderBottom: "1px solid rgb(230 229 231)" }}
          container
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          p={5}
          display={"flex"}
          gap={1}
        >
          <MDBox width={80} borderRadius={20} textAlign="center" bgColor={"#000"}>
            <h1>{userInfo?.email.charAt(0)}</h1>
          </MDBox>
          <MDTypography variant="h4" fontWeight="medium">
            {userInfo?.email}
          </MDTypography>
          <Chip label={currentPlan?.name ? currentPlan?.name : "No Plan"} color={"secondary"} />
          <Grid justifyContent={"space-between"} container paddingX={"10%"}>
            <Grid
              display={"flex"}
              alignItems={"center"}
              gap={2}
              p={1.5}
              sx={{ border: "1px solid rgb(230 229 231)", borderRadius: 5 }}
            >
              <FileUploadIcon fontSize={"large"} color={"secondary"} />
              <Grid>
                <MDTypography variant="h6" fontWeight="medium">
                  File Uploads
                </MDTypography>
                <MDTypography variant="h6" fontWeight="medium">
                  {detailUploaded?.totalFileUploaded || 0}
                </MDTypography>
              </Grid>
            </Grid>
            <Grid
              display={"flex"}
              alignItems={"center"}
              gap={2}
              p={1.5}
              sx={{ border: "1px solid rgb(230 229 231)", borderRadius: 5 }}
            >
              <FileUploadIcon fontSize={"large"} color={"secondary"} />
              <Grid>
                <MDTypography variant="h6" fontWeight="medium">
                  GB uploaded
                </MDTypography>
                <MDTypography variant="h6" fontWeight="medium">
                  {detailUploaded?.totalGbUploaded ? detailUploaded?.totalGbUploaded.toFixed(2) : 0}
                </MDTypography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
          <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
            account
          </MDTypography>
          <MDBox display="flex" alignItems="center" mb={0.5}>
            <Grid container spacing={2}>
              <Grid item xs={4} md={4} xl={4}>
                <MDBox mt={0.5}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Email:
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={8} md={8} xl={8}>
                <MDBox width="80%" ml={0.5}>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    {userInfo?.email}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          {customer && (
            <>
              <MDTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                textTransform="uppercase"
              >
                Billing info
              </MDTypography>
              <MDBox display="flex" alignItems="center" mb={0.5}>
                <Grid container spacing={2}>
                  <Grid item xs={4} md={4} xl={4}>
                    <MDBox mt={0.5}>
                      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        Email:
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={8} md={8} xl={8}>
                    <MDBox width="80%" ml={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {customer?.email}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox display="flex" alignItems="center" mb={0.5}>
                <Grid container spacing={2}>
                  <Grid item xs={4} md={4} xl={4}>
                    <MDBox mt={0.5}>
                      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                        Name:
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={8} md={8} xl={8}>
                    <MDBox width="80%" ml={0.5}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {customer?.name}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </>
          )}
        </MDBox>
      </Card>
    </div>
  );
}

export default PlatformSettings;
