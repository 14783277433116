import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import moment from "moment/moment";
import Card from "@mui/material/Card";
import useConnection from "../../../../config/zustand";
import Button from "@mui/material/Button";
import RivalzApi from "../../../../config/axios/RivalzApi";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";

function CreditInfo() {
  const { creditInfo, setBackdrop } = useConnection();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleGetCredit = async () => {
    try {
      setBackdrop(true);
      await RivalzApi.createCredit();
      await queryClient.refetchQueries({ queryKey: ["creditInfo"] });
      toast.success("Get credit successfully");
    } catch (e) {
      toast.error(e.message);
      if (e.message === "Please register a payment method first") {
        setTimeout(() => {
          navigate("/billing");
        }, 1000);
      }
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <>
      <Card sx={{ boxShadow: "none", width: "100%" }}>
        <MDBox
          p={2}
          sx={{
            borderBottom: creditInfo ? "1px solid #e0e0e0" : "none",
            color: "#7b809a",
          }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Credit Info
          </MDTypography>
          <Button
            variant="outlined"
            style={{ color: "#000", borderColor: "gray" }}
            disabled={creditInfo}
            onClick={handleGetCredit}
          >
            Create Credit
          </Button>
        </MDBox>
        {creditInfo && (
          <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
            <MDBox display="flex" alignItems="center" mb={0.5}>
              <Grid container spacing={2}>
                <Grid item xs={4} md={6} xl={6}>
                  <MDBox mt={0.5}>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Credit id:
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={8} md={6} xl={6}>
                  <MDBox ml={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      {creditInfo?._id}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5}>
              <Grid container spacing={2}>
                <Grid item xs={4} md={6} xl={6}>
                  <MDBox mt={0.5}>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Amount:
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={8} md={6} xl={6}>
                  <MDBox width="80%" ml={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      {creditInfo?.amount}$
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5}>
              <Grid container spacing={2}>
                <Grid item xs={4} md={6} xl={6}>
                  <MDBox mt={0.5}>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Amount paid:
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={8} md={6} xl={6}>
                  <MDBox width="80%" ml={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      {creditInfo?.amountPaid?.toFixed(2)}$
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox display="flex" alignItems="center" mb={0.5}>
              <Grid container spacing={2}>
                <Grid item xs={4} md={6} xl={6}>
                  <MDBox mt={0.5}>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Expiry date:
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={8} md={6} xl={6}>
                  <MDBox width="80%" ml={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      {moment(creditInfo?.expiryDate).format("DD/MM/YYYY")}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        )}
      </Card>
    </>
  );
}

export default CreditInfo;
