import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import CreditInfo from "./components/CreditInfo";
import GenerateApiKey from "../api/components/GenerateApiKey";
import StripeProducts from "../api/components/StripeProducts";

function Overview() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} xl={6}>
            <PlatformSettings />
          </Grid>
          <Grid item xs={12} md={12} xl={6}>
            <Grid
              sx={{ height: "100%" }}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <Grid>
                <GenerateApiKey />
              </Grid>
              <Grid marginTop={{ md: 1, xl: 0 }}>
                <CreditInfo />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <StripeProducts />
      </MDBox>
    </DashboardLayout>
  );
}

export default Overview;
