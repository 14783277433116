import Card from "@mui/material/Card";
import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import useConnection from "../../../config/zustand";
import IconButton from "@mui/material/IconButton";
import { ContentCopy } from "@mui/icons-material";
import toast from "react-hot-toast";

function GenerateApiKey() {
  const { jwt } = useConnection();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(jwt);
    toast.success("Api Key Copied to Clipboard");
  };

  return (
    <Card id={"api"} sx={{ boxShadow: "none" }}>
      <MDBox
        p={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          borderBottom: "1px solid #e0e0e0",
          color: "#7b809a",
        }}
      >
        <MDTypography variant="h6" fontWeight="medium">
          Api Key
        </MDTypography>
      </MDBox>
      <MDBox px={2} pb={6} pt={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Api key</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={"password"}
                value={jwt}
                disabled={true}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      onClick={copyToClipboard}
                    >
                      <ContentCopy />
                    </IconButton>
                  </InputAdornment>
                }
                label="Api key"
              />
            </FormControl>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default GenerateApiKey;
